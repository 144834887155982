import { NhostClient } from '@nhost/nhost-js'

const nhost = new NhostClient({
  backendUrl: 'https://gogujhibshcnojjhvonu.nhost.run'
  
  // replace this with the backend URL of your app
})


export { nhost };

