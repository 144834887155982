import React from 'react';
import { Redirect } from 'react-router-dom';

import { APICore } from '../helpers/api/apiCore';
import { useNhostAuth } from '@nhost/react-auth';
const Root = () => {
    const api = new APICore();
    const {isAuthenticated} = useNhostAuth();
    const getRootUrl = () => {
        let url: string = 'auth/login';

        // check if user logged in or not and return url accordingly
        if (isAuthenticated === false) {
            url = 'Landing';
        } else {
            url = 'dashboard/analytics';
        }
        return url;
    };

    const url = getRootUrl();

    return <Redirect to={`/${url}`} />;
};

export default Root;
