import React from 'react';
import ReactDOM from 'react-dom';

import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
// import { NhostApolloProvider } from '@nhost/react-apollo'
// import { NhostAuthProvider } from '@nhost/react-auth'
// // import { Nhost } from '@nhost/client'
// // import { NhostProvider } from '@nhost/react'


// // const nhost = new Nhost({
// //     backendUrl: 'https://gogujhibshcnojjhvonu.nhost.run'
// //   })
// import {nhost} from './nhost'

ReactDOM.render(

    <Provider store={configureStore({})}>
     
        <App />
       
        
    </Provider>
 
  
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
