// @ts-nocheck
import React from 'react';

import Routes from './routes/Routes';

import { configureFakeBackend } from './helpers';

// Themes
// For Default import Theme.scss
import './assets/scss/Theme-Dark.scss';
import { NhostApolloProvider } from '@nhost/react-apollo'
import { NhostAuthProvider } from '@nhost/react-auth'
// import { Nhost } from '@nhost/client'
// import { NhostProvider } from '@nhost/react'


// const nhost = new Nhost({
//     backendUrl: 'https://gogujhibshcnojjhvonu.nhost.run'
//   })
import { nhost } from './nhost'
// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
configureFakeBackend();

const App = () => {
    return (
   
    
        <>
            <NhostAuthProvider nhost={nhost}>
            <NhostApolloProvider nhost={nhost}>
            <Routes></Routes>
            </NhostApolloProvider>
        </NhostAuthProvider>
            </>
      
    );
};

export default App;
